import { trackBillingEvent } from '@mfe/to-be-migrated/redux/analytics';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  refetchBillingInfo,
  selectBillingInfo,
} from '@mfe/to-be-migrated/redux/billingInfo';
import { Route, setBillingNav } from '@mfe/to-be-migrated/redux/billingNav';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import {
  initiatePayment,
  resetPayments,
  selectPayments,
  setPayments,
  retrieveTransactionId,
  PaymentMethodType,
} from '@mfe/to-be-migrated/redux/payments';
import { Invoice, Locale, VppError } from '@mfe/shared/schema-types';
import { BOLETO, debounce, useTrackTiming } from '@mfe/shared/util';
import { PageAlert, Txt, Checkbox, InlineLink } from '@vst/beam';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../shared-ui/card';
import Divider from '../../shared-ui/divider';
import Gradient from '../../shared-ui/gradient';
import sharedStyles from '../../shared/styles.module.scss';
import DifferentMethod from '../different-method';
import styles from '../vpp.module.scss';
import {
  NO_INTERNET_CONNECTION,
  handleVppMessage,
  iframeProps,
  postMessageToVPS,
} from '../utils';
import PaymentAmount, { AmountType } from './payment-amount';
import PaymentButtons from './payment-buttons';
import PaymentMethod, { MethodType } from './payment-method';
import PaymentStatus from './payment-status';
import { scrollToTop } from '@mfe/shared/redux/utils';
import { TitleCard } from '../../shared-ui/title';
import { ANALYTICS_EVENT_NAME } from '../../shared/constants';
import { useNetworkStatus } from './useNetworkStatus';

const INIT_PAYMENT = JSON.stringify({
  initConnection: true,
  pageFunction: 'Payment',
});

const OneTimePayment = ({ invoice }: { invoice?: Invoice | null }) => {
  const {
    billingInfo: { autoPay, billingAccount },
  } = useSelector(selectBillingInfo);
  const {
    user: { productInstanceStatus },
  } = useSelector(selectUser);
  const { locale } = useSelector(selectLocale);

  const config = useSelector(selectConfig);
  const payments = useSelector(selectPayments);
  const dispatch = useDispatch();
  const isOffline = useNetworkStatus();
  const {
    url,
    paymentMethod,
    height,
    txnAmount,
    paymentMethodType,
    frameReady,
    submit,
    success,
    error,
    saveOTP,
    loading,
  } = payments;
  const validTxnAmount = txnAmount >= 5 && txnAmount <= 999.99;

  useLayoutEffect(() => {
    dispatch(resetPayments());
  }, [dispatch]);

  const setPaymentsAndCreateTxnId = useMemo(
    () =>
      debounce(() => {
        dispatch(retrieveTransactionId(invoice));
      }, 500),
    []
  );

  useEffect(() => {
    if (!invoice && (txnAmount < 5 || txnAmount > 999.99)) return;

    setPaymentsAndCreateTxnId();
  }, [dispatch, invoice, txnAmount]);

  const balance = billingAccount?.balance?.value ?? 0;
  const [amountType, setAmountType] = useState(() =>
    balance <= 0 ? AmountType.other : AmountType.balance
  );

  const hasValidAutoPay =
    autoPay?.paymentMethod && autoPay.paymentMethod !== BOLETO;

  const [methodType, setMethodType] = useState(() =>
    hasValidAutoPay ? MethodType.pof : MethodType.other
  );

  const disabled = submit || !validTxnAmount;
  const showPaymentButtons = methodType === MethodType.pof || !frameReady;
  const frameLoading = methodType === MethodType.other && !frameReady;
  const disablePaymentButtons = disabled || frameLoading;
  const amount =
    locale.userLocale === Locale.PtBr
      ? invoice?.amountOwedOnInvoice?.value ?? txnAmount
      : txnAmount;

  const { t } = useTranslation();

  // submit
  const paymentDetails = useMemo(
    () => ({
      payment_type: methodType === MethodType.pof ? 'recurring' : 'one-time',
      payment_amount_type:
        amountType === AmountType.balance ? 'full' : 'partial',
      agent_fee: 'not_applicable',
      payment_value: txnAmount,
      payment_currency: billingAccount?.balance?.alphabeticCode ?? '',
      payment_method_type: paymentMethodType,
    }),
    [
      amountType,
      billingAccount?.balance?.alphabeticCode,
      methodType,
      paymentMethodType,
      txnAmount,
    ]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.makePaymentSubmitted,
        version: '1-0-0',
        data: {
          submission_method: 'submit_button',
          submission_zone: 'make_a_payment_screen',
        },
      })
    );
    dispatch(setPayments({ submit: true }));
    dispatch(initiatePayment(invoice));
  }, [dispatch, invoice]);

  const onCancel = useCallback(
    () => dispatch(setBillingNav({ route: Route.Home })),
    [dispatch]
  );

  const onSuccess = useCallback(() => {
    dispatch(
      trackBillingEvent({
        eventName: ANALYTICS_EVENT_NAME.makePaymentSuccessful,
        version: '1-0-1',
        data: paymentDetails,
      })
    );
    dispatch(refetchBillingInfo());
    dispatch(setPayments({ success: true }));
    dispatch(scrollToTop());
  }, [dispatch, paymentDetails]);

  const onError = useCallback(
    (error?: VppError) => {
      const paymentDetailsFailure = {
        ...paymentDetails,
        failure_reason: error?.message ?? 'Payment Failed.',
      };
      dispatch(
        trackBillingEvent({
          eventName: ANALYTICS_EVENT_NAME.makePaymentFailed,
          version: '1-0-1',
          data: paymentDetailsFailure,
        })
      );

      dispatch(setPayments({ error, submit: false }));
      dispatch(scrollToTop());
    },
    [dispatch, paymentDetails]
  );

  useEffect(() => {
    const handleVppIframeMessage = (event: MessageEvent) =>
      handleVppMessage(
        event,
        { ...payments, onSuccess, onError, onCancel },
        config.vppUrl,
        dispatch,
        isOffline
      );
    window.addEventListener('message', handleVppIframeMessage);

    return () => window.removeEventListener('message', handleVppIframeMessage);
  }, [payments, config, onSuccess, onError, onCancel, dispatch, isOffline]);

  useEffect(() => {
    dispatch(
      setPayments({
        paymentMethodType: config.setACHasDefault
          ? PaymentMethodType.BANK
          : PaymentMethodType.CARD,
      })
    );
  }, [dispatch, config]);

  useTrackTiming({
    eventName: 'VPPOneTimePaymentLoaded',
    isLoading: !frameReady,
  });

  const noInternetConnectionError = error?.message === NO_INTERNET_CONNECTION;

  if (success) {
    return (
      <PaymentStatus
        accountStatus={productInstanceStatus}
        balance={billingAccount?.balance}
        txnAmount={txnAmount}
        paymentMethod={paymentMethod}
        saveOTP={saveOTP}
      />
    );
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['paymentContainer']}>
        {error && (
          <div className={sharedStyles['notificationsContainer']}>
            <PageAlert
              title={t('VPP:error.oneTimePayment.title')}
              variant={'error'}
              description={<AlertDescription />}
              onClose={() => {
                dispatch(setPayments({ error: null }));
                dispatch(retrieveTransactionId(invoice));
              }}
              className={sharedStyles['notification']}
            />
          </div>
        )}

        <Card className={styles['card']}>
          <Gradient />

          <div className={styles['container']}>
            <div className={styles['titleContainer']}>
              <Txt variant="heading4">{t('VPP:makeAPayment')}</Txt>
            </div>

            <div className={styles['amountContainer']}>
              <TitleCard title={t('VPP:paymentAmountTitle')}>
                <PaymentAmount
                  balance={billingAccount?.balance}
                  invoice={invoice}
                  amountType={amountType}
                  setAmountType={setAmountType}
                  setMethodType={setMethodType}
                />
              </TitleCard>
            </div>

            {hasValidAutoPay && (
              <div className={styles['methodContainer']}>
                <TitleCard title={t('VPP:paymentMethodTitle')}>
                  <PaymentMethod
                    autoPay={autoPay}
                    methodType={methodType}
                    setMethodType={setMethodType}
                  />
                </TitleCard>
              </div>
            )}

            {hasValidAutoPay && (
              <div
                className={styles['checkboxContainer']}
                data-cy="different-payment-method-checkbox"
              >
                <Checkbox
                  id="different-payment-method"
                  checked={methodType === MethodType.other}
                  onChange={() => {
                    if (disabled) {
                      return;
                    }
                    setMethodType(
                      methodType === MethodType.other
                        ? MethodType.pof
                        : MethodType.other
                    );
                  }}
                  label={
                    <Txt variant="bodySmallRegular" color="regular">
                      {t('VPP:differentPaymentMethod')}
                    </Txt>
                  }
                />
                {!validTxnAmount ? (
                  <Txt
                    component="p"
                    mt="8px"
                    variant="tinyItalic"
                    color="subtle"
                  >
                    {t('VPP:paymentMethodTip')}
                  </Txt>
                ) : null}
              </div>
            )}
            <div className={styles['differentMethodContainer']}>
              <div
                style={{
                  display:
                    methodType === MethodType.other &&
                    !noInternetConnectionError
                      ? 'flex'
                      : 'none',
                }}
              >
                {loading && (
                  <DifferentMethod frameReady={frameReady}>
                    <iframe
                      title="One Time Payment"
                      height={height}
                      src={url}
                      onLoad={() => postMessageToVPS(INIT_PAYMENT, url)}
                      {...iframeProps}
                    />
                  </DifferentMethod>
                )}
              </div>
            </div>

            {showPaymentButtons && (
              <>
                <Divider />

                <div className={styles['footerContainer']}>
                  <PaymentButtons
                    txnAmount={amount}
                    submit={submit}
                    balance={billingAccount?.balance}
                    disabled={disablePaymentButtons}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                  />
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

const AlertDescription = () => {
  const { t } = useTranslation();

  return (
    <Txt variant="bodySmallRegular" color="subtle">
      {t('VPP:error.oneTimePayment.description')}{' '}
      <InlineLink
        style={{ whiteSpace: 'nowrap' }}
        variant="primary"
        href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
      >
        {t('Global:callCustomerSupportPhoneNumberDisplay')}
      </InlineLink>
    </Txt>
  );
};

export default OneTimePayment;
