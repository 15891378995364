import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AutopayMethod, Invoice, VppError } from '@mfe/shared/schema-types';
import { ErrorsObj } from '@mfe/shared/redux/graphql';

export enum PaymentMethodType {
  CARD = 'credit_card',
  BANK = 'bank_transfer',
  PAYPAL = 'paypal',
}

export interface PaymentsState {
  // Manage txn id
  txnAmount: number;
  url: string;

  // Manage vpp events
  height: number;
  paymentMethodType: PaymentMethodType;
  paypalOpen: boolean;

  // Manage flow
  frameReady: boolean;
  submit: boolean;
  success: boolean | undefined;
  saveOTP: boolean | undefined;
  paymentMethod: Partial<AutopayMethod> | undefined;
  error: VppError | null | ErrorsObj;
  loading: boolean;
}

export const initialState: PaymentsState = {
  // Manage txn id
  txnAmount: 0,
  url: '',

  // Manage vpp events
  height: 558,
  paymentMethodType: PaymentMethodType.CARD,
  paypalOpen: false,

  // Manage flow
  frameReady: false,
  submit: false,
  saveOTP: false,
  success: undefined,
  paymentMethod: undefined,
  error: null,
  loading: false,
};

export type PaymentAction = PayloadAction<Partial<Invoice> | null | undefined>;

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    retrieveTransactionId: (state, action: PaymentAction) => state,
    initiatePayment: (state, action: PaymentAction) => state,
    setPayments: (state, { payload }: { payload: Partial<PaymentsState> }) => ({
      ...state,
      ...payload,
    }),
    setTransactionIdError: (
      state,
      { payload }: { payload: Partial<PaymentsState> }
    ) => {
      state.error = payload.error || null;
    },
    setLoading: (state, { payload }: { payload: boolean }) => {
      state.loading = payload || false;
    },
    resetPayments: () => initialState,
  },
});

export const {
  retrieveTransactionId,
  initiatePayment,
  setPayments,
  setTransactionIdError,
  setLoading,
  resetPayments,
} = paymentsSlice.actions;

export const selectPayments = (state: { payments: PaymentsState }) =>
  state.payments;
